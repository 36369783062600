<template>
  <div
    class="page"
  >
    <h1>{{ $t('home.welcome').toUpperCase() }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="less" scoped>
  h1 {
    position: absolute;
    left: 40%;
    top: 40%;
    color: @blue;
    font-size: 5em;
  }
</style>
